import React from "react";
import "./Progress.scss";
import {Fade} from "react-reveal";
import Build from "../../assets/lottie/build";
import DisplayLottie from "../../components/displayLottie/DisplayLottie";
import { useTranslation } from 'react-i18next';

export default function StackProgress() {
  const { t } = useTranslation(); 

  const techStack = {
    viewSkillBars: true, //Set it to true to show Proficiency Section
    experience: [
      {
        Stack: t("sections.proficiency.stack-1"), //Insert stack or technology you have experience in
        progressPercentage: "75%" //Insert relative proficiency in percentage
      },
      {
        Stack: t("sections.proficiency.stack-2"),
        progressPercentage: "80%"
      },
      {
        Stack: t("sections.proficiency.stack-3"),
        progressPercentage: "50%"
      },
      {
        Stack: t("sections.proficiency.stack-4"),
        progressPercentage: "70%"
      }
    ],
    displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
  };

  if (techStack.viewSkillBars) {
    return (
      <Fade bottom duration={1000} distance="20px">
        <div className="skills-container">
          <div className="skills-bar">
            <h1 className="skills-heading">{t("sections.proficiency.name")}</h1>
            {techStack.experience.map((exp, i) => {
              const progressStyle = {
                width: exp.progressPercentage
              };
              return (
                <div key={i} className="skill">
                  <p>{exp.Stack}</p>
                  <div className="meter">
                    <span style={progressStyle}></span>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="skills-image">
            <DisplayLottie animationData={Build} />
          </div>
        </div>
      </Fade>
    );
  }
  return null;
}
