import React, {useContext} from "react";
import "./WorkExperience.scss";
import ExperienceCard from "../../components/experienceCard/ExperienceCard";
import {Fade} from "react-reveal";
import StyleContext from "../../contexts/StyleContext";
import { useTranslation } from 'react-i18next';
import altisLogo from '../../assets/images/altis-logo.png';
import ithouseLogo from '../../assets/images/ithouse-logo.png';
import bupaLogo from '../../assets/images/bupa-logo.png';
import arvatoLogo from '../../assets/images/arvato-logo.png';

export default function WorkExperience() {
  const {isDark} = useContext(StyleContext);
  const { t } = useTranslation(); 

  const workExperiences = {
    display: true,
    experience: [
      {
        role: t('softwareEngineer'),
        company: t('companies.arvato.name'),
        companylogo: arvatoLogo,
        date: `${t('months.june')} 2024 – ${t('present')}`,
        desc: t('companies.arvato.desc'),
        // descBullets: [
        //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit"
        // ]
      },
      {
        role: t('softwareExpert'),
        company: t('companies.bupa.name'),
        companylogo: bupaLogo,
        date: `${t('months.february')} 2023 – ${t('months.june')} 2024`,
        desc: t('companies.bupa.desc')
      },
      {
        role: t('softwareEngineer'),
        company: t('companies.ithouse.name'),
        companylogo: ithouseLogo,
        date: `${t('months.november')} 2022 – ${t('months.february')} 2023`,
        desc: t('companies.ithouse.desc')
      },
      {
        role: t('softwareEngineer'),
        company: t('companies.altis.name'),
        companylogo: altisLogo,
        date: `${t('months.june')} 2020 - ${t('months.october')} 2022`,
        desc: t('companies.altis.desc')
      }
    ]
  };

  if (workExperiences.display) {
    return (
      <div id="experience">
        <Fade bottom duration={1000} distance="20px">
          <div className="experience-container" id="workExperience">
            <div>
              <h1 className="experience-heading">{t('sections.workexp.name')}</h1>
              <div className="experience-cards-div">
                {workExperiences.experience.map((card, i) => {
                  return (
                    <ExperienceCard
                      key={i}
                      isDark={isDark}
                      cardInfo={{
                        company: card.company,
                        desc: card.desc,
                        date: card.date,
                        companylogo: card.companylogo,
                        role: card.role,
                        descBullets: card.descBullets
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </Fade>
      </div>
    );
  }
  return null;
}
