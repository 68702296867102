import React from "react";
import "./Education.scss";
import EducationCard from "../../components/educationCard/EducationCard";
import { useTranslation } from 'react-i18next';
import imuLogo from '../../assets/images/imu-logo.png';

export default function Education() {
  const { t } = useTranslation(); 

  const educationInfo = {
    display: true, // Set false to hide this section, defaults to true
    schools: [
      {
        schoolName: t("sections.education.s1.schoolName"),
        logo: imuLogo,
        subHeader: t("sections.education.s1.subHeader"),
        duration: `${t('months.june')} 2016 – ${t('months.june')} 2020`,
        desc: t("sections.education.s1.desc"),
        descBullets: []
      }
    ]
  };

  if (educationInfo.display) {
    return (
      <div className="education-section" id="education">
        <h1 className="education-heading">{t("sections.education.name")}</h1>
        <div className="education-card-container">
          {educationInfo.schools.map((school, index) => (
            <EducationCard key={index} school={school} />
          ))}
        </div>
      </div>
    );
  }
  return null;
}
