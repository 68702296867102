import React, {useState, useContext} from "react";
import emoji from "react-easy-emoji";
import StyleContext from "../../contexts/StyleContext";
import {useTranslation} from "react-i18next";
import "./ToggleSwitchLang.scss";
import i18next from "i18next";

const ToggleSwitchLang = () => {
  const {i18n} = useTranslation();
  // const {isDark} = useContext(StyleContext);
  const [isCheckedLng, setCheckedLng] = useState((i18next.language || window.localStorage.i18nextLng) === "tr" ? false : true);
  // const styleContext = useContext(StyleContext);
  
  const changeLanguageHandler = event => {
    const lang = event.target.checked === false ? "tr" : "en-US";
    setCheckedLng(!isCheckedLng);
    i18n.changeLanguage(lang);
  };

  return (
    <label className="switch">
      <input
        type="checkbox"
        checked={isCheckedLng}
        onChange={changeLanguageHandler}
      />
      <span className="slider round">
        <span className="emoji">{isCheckedLng ? emoji("🇬🇧") : emoji("🇹🇷")}</span>
      </span>
    </label>
  );
};
export default ToggleSwitchLang;
